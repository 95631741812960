.mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.mapboxgl-popup-close-button {
    font-size: 20px; 
    font-family: Tahoma, Verdana, sans-serif; 
    color: #838383; 
    font-weight: bold;
}
.mapboxgl-popup-close-button:hover {
    color: #999; 
    background-color: white; 
    border-top-right-radius: 12px;
}