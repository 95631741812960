.button-add-container {
    position: relative;
    bottom: 75px;
}

.button-add {
    position: absolute;
    right: 15px;
    font-size: 28px; 
	background-color:#d0451b;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:30px;
	border:4px solid #942911;
	display:block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-weight:bold;
    padding:12px 19px;
	text-decoration:none;
	text-shadow:0px 1px 0px #854629;
}

.button-remove {
    position: absolute;
    right: 65px;
    font-size: 28px; 
	background-color:#d0451b;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:30px;
	border:4px solid #942911;
	display:block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-weight:bold;
    padding:12px 22px;
	text-decoration:none;
	text-shadow:0px 1px 0px #854629;
}

.button-reload {
    position: absolute;
    right: 140px;
    font-size: 28px; 
	background-color:#d0451b;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:30px;
	border:4px solid #942911;
	display:block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-weight:bold;
    padding:12px 18px;
	text-decoration:none;
	text-shadow:0px 1px 0px #854629;
}